/**
 * yleinen taitto mikäli className on muuttujassa view
 * jos  className={`${WEBHOTELS_ARTICLE_CLASS_NAME}`} mahdollistetaan erikoisempi taitto
 */

import { ReactNode, useCallback } from "react";
import { ALL_FEATURES_CLASS_NAME, BUTTON_CLASS_NAME } from "../../../../core/constants/className";
import "./AllFeatures.scss";

export interface AllFeaturesProps {
  readonly className   ?: string;
  readonly children    ?: ReactNode;
  readonly buttonLabel  : string;
  readonly hideButtonLabel  ?: string;
  readonly visible      : boolean;
  readonly setVisible   : (visible: boolean) => void;
}

export function AllFeatures (props: AllFeaturesProps) {
  const className: string | undefined = props?.className;
  const children: ReactNode | undefined = props?.children;
  const visible     = props?.visible ?? false;
  const setVisible  = props?.setVisible;
  const buttonLabel = props?.buttonLabel;
  const buttonLabelHide = props?.hideButtonLabel ?? buttonLabel;
  const buttonClick = useCallback(
      () => {
        setVisible(!visible);
      },
      [
          setVisible,
          visible
      ]
  );
  return (
    <div className={ALL_FEATURES_CLASS_NAME + (className ? ` ${className}` : '')}>
       {visible ? (
        <div className={`${ALL_FEATURES_CLASS_NAME}-all-features-hidden`}>{children}</div>
      ) : null}
      <button onClick={buttonClick} className={`${ALL_FEATURES_CLASS_NAME}-white ${BUTTON_CLASS_NAME}`}>
        {!visible ? buttonLabel : buttonLabelHide}
        {visible ? (
          <span className={`${ALL_FEATURES_CLASS_NAME}-white-noextra`}>
            <>&#10095;</>
          </span>
        ) : (
          <span className={`${ALL_FEATURES_CLASS_NAME}-white-extra`}>
            <>&#10095;</>
          </span>
        )}
      </button>
      <hr />
    </div>
  );
}
